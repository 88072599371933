import Loader from '@components/loader'
import { authenticate, login, resetCookiesAndLocalStorages } from '@lib/auth'
import * as Sentry from '@sentry/nextjs'
import LoginImage from 'assets/login-bg.jpeg'
import ParionLogo from 'assets/pari_logo_small.png'
import Image from 'next/image'
import Router from 'next/router'
import React, { useState } from 'react'

export default function Login() {
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()
    setMessage(null)
    const data = {
      email: e.target.email.value,
      password: e.target.password.value,
    }
    try {
      setIsLoading(true)
      const response = await login(data)
      if (response.status === 200) {
        Object.assign(data, { token: response.data })
        authenticate(data, () => Router.push(`/`))
      }
      if (response.status === 400) {
        setMessage(response.data)
      }
    } catch (e) {
      setMessage(e.message)
      Sentry.captureMessage('Login olurken bir hata olustu - Login Context')
      Sentry.captureException(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          {/*  LOGIN HEADER*/}
          <div>
            <Image className="h-32 w-auto" src={ParionLogo} alt="Workflow" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Parion Kazisi</h2>
          </div>
          {/*  LOGIN HEADER*/}
          {/* LOGIN FORM*/}
          <div className="mt-8">
            <div>
              <div className="mt-6">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email adresi
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Sifre
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember_me"
                        name="remember_me"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                        Beni Hatirla
                      </label>
                    </div>

                    <div className="text-sm">
                      <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Sifremi unuttum
                      </a>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Giris Yap
                    </button>
                    {isLoading && (
                      <div className="my-2 my-2 flex justify-center">
                        <Loader />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          {message && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {message}
            </p>
          )}
          {/* LOGIN FORM*/}
          <p className="mt-4 text-sm text-gray-400 max-w">Copyright © 2021 Celsus Data</p>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <Image
          className="absolute inset-0 h-full w-full object-cover"
          src={LoginImage}
          alt=""
          layout="fill"
          placeholder="blur"
          blurDataURL={
            'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/4QCMRXhpZgAATU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAABIAAAAAQAAAEgAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAAqgAwAEAAAAAQAAAA8AAAAA/+0AOFBob3Rvc2hvcCAzLjAAOEJJTQQEAAAAAAAAOEJJTQQlAAAAAAAQ1B2M2Y8AsgTpgAmY7PhCfv/CABEIAA8ACgMBIgACEQEDEQH/xAAfAAABBQEBAQEBAQAAAAAAAAADAgQBBQAGBwgJCgv/xADDEAABAwMCBAMEBgQHBgQIBnMBAgADEQQSIQUxEyIQBkFRMhRhcSMHgSCRQhWhUjOxJGIwFsFy0UOSNIII4VNAJWMXNfCTc6JQRLKD8SZUNmSUdMJg0oSjGHDiJ0U3ZbNVdaSVw4Xy00Z2gONHVma0CQoZGigpKjg5OkhJSldYWVpnaGlqd3h5eoaHiImKkJaXmJmaoKWmp6ipqrC1tre4ubrAxMXGx8jJytDU1dbX2Nna4OTl5ufo6erz9PX29/j5+v/EAB8BAAMBAQEBAQEBAQEAAAAAAAECAAMEBQYHCAkKC//EAMMRAAICAQMDAwIDBQIFAgQEhwEAAhEDEBIhBCAxQRMFMCIyURRABjMjYUIVcVI0gVAkkaFDsRYHYjVT8NElYMFE4XLxF4JjNnAmRVSSJ6LSCAkKGBkaKCkqNzg5OkZHSElKVVZXWFlaZGVmZ2hpanN0dXZ3eHl6gIOEhYaHiImKkJOUlZaXmJmaoKOkpaanqKmqsLKztLW2t7i5usDCw8TFxsfIycrQ09TV1tfY2drg4uPk5ebn6Onq8vP09fb3+Pn6/9sAQwAICAgICQgJCgoJDQ4MDg0TERAQERMcFBYUFhQcKxsfGxsfGysmLiUjJS4mRDUvLzVETkI+Qk5fVVVfd3F3nJzR/9sAQwEICAgICQgJCgoJDQ4MDg0TERAQERMcFBYUFhQcKxsfGxsfGysmLiUjJS4mRDUvLzVETkI+Qk5fVVVfd3F3nJzR/9oADAMBAAIRAxEAAAG7RQIR/wD/2gAIAQEAAQUCTeofv0bMJfJL/9oACAEDEQE/Af8Agl//2gAIAQIRAT8B/wA7/9oACAEBAAY/AuD9l+3R8X//xAAzEAEAAwACAgICAgMBAQAAAgsBEQAhMUFRYXGBkaGxwfDREOHxIDBAUGBwgJCgsMDQ4P/aAAgBAQABPyGF/X/geYvT/j//2gAMAwEAAhEDEQAAENv/xAAzEQEBAQADAAECBQUBAQABAQkBABEhMRBBUWEgcfCRgaGx0cHh8TBAUGBwgJCgsMDQ4P/aAAgBAxEBPxDH6L//2gAIAQIRAT8Q4v8A/9oACAEBAAE/EB8fHZf6q6sv3/qgw506v4oYDNDnL//Z'
          }
        />
      </div>
    </div>
  )
}
export async function getServerSideProps(context) {
  const hasCookie = /celsuswebtoken=([\d\w]+)/gm.exec(context.req.headers.cookie)
  if (hasCookie) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    }
  } else {
    resetCookiesAndLocalStorages()
  }
  return { props: { pageName: 'login' } }
}
